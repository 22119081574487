import React, { useRef } from 'react';
import { useMidPosition } from '@fingo/lib/hooks';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Rose, HomeBackground } from '@fingo/lib/assets';
import LoadSrcBefore from '../components/images/LoadSrcBefore';

const Home = () => {
  const paperRef = useRef(null);
  const iconRef = useRef(null);
  const midStackPosition = useMidPosition(paperRef);

  return (
    <LoadSrcBefore src={HomeBackground}>
      <Icon
        ref={iconRef}
        sx={{
          position: 'absolute',
          top: midStackPosition - 50,
          width: '100%',
          height: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          zIndex: 1,
        }}
      >
        <img src={Rose} alt="rose" style={{ width: '100%' }} />
      </Icon>
      <Grid
        container
        minHeight="100%"
        p={8}
        justifyContent="center"
        sx={{
          backgroundImage: `url(${HomeBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Stack
          ref={paperRef}
          bgcolor="gray.A200"
          borderRadius={4}
          py={6}
          sx={(theme) => ({
            px: 16,
            maxHeight: 450,
            [theme.breakpoints.down('lg')]: {
              px: 10,
              maxHeight: 'inherit',
            },
            [theme.breakpoints.down('md')]: {
              px: 4,
              maxHeight: 'inherit',
            },
            [theme.breakpoints.down('sm')]: {
              px: 2,
              maxHeight: 'inherit',
            },
          })}
          spacing={2}
          width="100%"
          maxWidth={1085 - 256}
        >
          <Typography
            width="100%"
            align="center"
            variant="h1"
            color="primary"
          >
            Bienvenidos
          </Typography>
        </Stack>
      </Grid>
    </LoadSrcBefore>
  );
};

export default Home;
