import React from 'react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import { render } from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import TrackerProvider from '@fingo/lib/components/tracker/TrackerProvider';
import theme from '@fingo/lib/theme';
import SnackbarAlert from '@fingo/lib/contexts/snack-bar';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TranslationProvider from '@fingo/lib/components/translation/TranslationProvider';
import client from '@fingo/lib/apollo';
import { Login, App } from './views';
import PrivateRoute from './components/router/PrivateRoute';
import { Layout } from './components/layout';

const Lucila = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ApolloProvider client={client}>
          <StyledEngineProvider injectFirst>
            <SnackbarAlert />
            <Router>
              <TranslationProvider>
                <TrackerProvider frontendName="lucila">
                  <Layout>
                    <Switch>
                      <PrivateRoute path="/app" component={App} />
                      <Route exact path="/login" component={Login} />
                      <Route exact path="/" component={Login} />
                    </Switch>
                  </Layout>
                </TrackerProvider>
              </TranslationProvider>
            </Router>
          </StyledEngineProvider>
        </ApolloProvider>
      </LocalizationProvider>
    </CssBaseline>
  </ThemeProvider>
);

render(<Lucila />, document.getElementById('root'));
